<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="text-left">
        <div class="lb-1">
          <v-img
            class="player-border avatar-center mt-2 mb-2 ml-4 draft-avatar"
            size="75"
            :src="userData.avatar"
          ></v-img>

          <div class="top-1 mt-2 ml-2">
            <v-img
              width="20"
              height="100%"
              :src="'/flags/' + userData.country + '.png'"
              class="user-flag ml-1 mr-1"
            >
            </v-img>

            <v-img
              width="22"
              height="22"
              :src="'/faceit/faceit' + userData.csgo_level + '.svg'"
              class="faceitlvlteam"
            ></v-img>
            <a class="player-link">
              {{ userData.nickname }}
            </a>
            <p
              v-if="userData.role"
              class="points-badge player-role"
            >
              {{ userData.role }}
            </p>
            <v-card-text class="no-padding">
              <p
                :class="userData.elo !== undefined ? 'points-badge' : 'points-no-content-badge'"
              >
                <span v-if="userData.elo !== undefined">{{ userData.elo }} ELO</span>
              </p>
              <p
                class="mt-1"
                :class="userData.statistics.avg_kd ? 'points-badge' : 'points-no-content-badge'"
              >
                <span v-if="userData.statistics.avg_kd">{{ userData.statistics.avg_kd }} KDR</span>
              </p>
              <p
                v-if="userData.esea_placement"
                class="mt-1"
                :class="userData.esea_placement ? 'points-badge-esea' : 'points-no-content-badge'"
              >
                {{ userData.esea_placement }}
              </p>
              <br>
              <p
                class="mt-1"
                :class="userData.statistics.match ? 'points-badge' : 'points-no-content-badge'"
              >
                <span v-if="userData.statistics.match">{{ userData.statistics.match }} Matches</span>
              </p>
              <p
                class="mt-1"
                :class="userData.statistics.win ? 'points-badge-w' : 'points-no-content-badge'"
              >
                <span v-if="userData.statistics.win">{{ userData.statistics.win }} Wins</span>
              </p>
              <p
                class="mt-1"
                :class="userData.statistics.loss ? 'points-badge-l' : 'points-no-content-badge'"
              >
                <span v-if="userData.statistics.loss">{{ userData.statistics.loss }} Losses</span>
              </p>
            </v-card-text>
          </div>
        </div>

        <v-card-text class="text-left">
          <div
            v-if="userData.team"
            class="info-list mt-1"
          >
            <b>Current team:</b> {{ userData.team }}
          </div>
          <div
            v-if="userData.availability"
            class="info-list"
          >
            <b>Available to play:</b> {{ userData.availability }}
          </div>
          <div
            v-if="userData.languages"
            class="info-list"
          >
            <b>Language:</b>
            <v-img
              v-for="(language, index) in userData.languages"
              :key="language + index"
              width="15"
              height="100%"
              :src="'flags/' + language + '.png'"
              class="draft-flag"
            ></v-img>
          </div>
          <p
            v-if="userData.bio"
            class="draft-text-badge mt-3"
          >
            {{ userData.bio }}
          </p>
        </v-card-text>

        <v-card-actions class="dense ml-1">
          <v-btn
            v-if="userData.steam"
            small
            color="darkbtn2"
            :href="'https://steamcommunity.com/profiles/' + steam"
            target="_blank"
            v-on="on"
          >
            <v-icon
              color="white"
              size="14"
              class="margin-bottom-draft mr-1"
            >
              {{ icons.mdiSteam }}
            </v-icon> Steam
          </v-btn>
          <v-btn
            v-if="userData.discord_id"
            small
            color="darkbtn2"
            :href="'https://discord.com/users/' + userData.discord_id"
            target="_blank"
          >
            <v-icon
              color="white"
              size="20"
              class="margin-bottom-draft mr-1"
            >
              {{ icons.mdiDiscord }}
            </v-icon>
          </v-btn>
          <v-btn
            v-if="userData.twitter_username"
            small
            color="darkbtn2"
            :href="'https://twitter.com/' + userData.twitter_username"
            target="_blank"
          >
            <v-icon
              color="white"
              size="20"
              class="margin-bottom-draft mr-1"
            >
              {{ icons.mdiTwitter }}
            </v-icon>
          </v-btn>
          <v-btn
            v-if="userData.twitch_username"
            small
            color="darkbtn2"
            :href="'https://twitch.tv/' + userData.twitch_username"
            target="_blank"
          >
            <v-icon
              color="white"
              size="20"
              class="margin-bottom-draft mr-1"
            >
              {{ icons.mdiTwitch }}
            </v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <p
            v-for="(role, index) in userData.ingameroles"
            v-if="userData.ingameroles"
            :key="role + index"
            class="points-badge"
          >
            {{ role }}
          </p>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiTrophy,
  mdiSteam,
  mdiAccountBoxOutline,
  mdiCommentTextMultiple,
  mdiTwitch,
  mdiTwitter,
  mdiDiscord,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import SteamID from 'steamid'

export default {
  components: {},
  props: {
    userData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    steam() {
      return this.userData.steam ? new SteamID(this.userData.steam).getSteamID64() : ''
    },
  },
  setup() {
    const isBioDialogOpen = ref(false)
    const isCardDetailsVisible2 = false
    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Users', 'Up to 10GB storage', 'Basic Support'],
    }

    return {
      isBioDialogOpen,
      standardPlan,
      isCardDetailsVisible2,
      icons: {
        mdiCommentTextMultiple,
        mdiTrophy,
        mdiSteam,
        mdiAccountBoxOutline,
        mdiTwitch,
        mdiTwitter,
        mdiDiscord,
      },
    }
  },
}
</script>
