<template>
  <v-card
    v-if="statistics !== undefined && statistics !== null"
    class="profile-cardstyle margin-fix"
  >
    <v-card-title class="align-start">
      <span class="font-weight-semibold">
        <span v-if="isUserLocal">Your</span> 
        <span v-else>User</span> 
        statistics - {{ statistics.division_name }}
      </span>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          md="2"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation (data.title).color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation (data.title).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ statistics[data.id] }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <div
    v-else
    class="progressbar-player-statistics d-flex align-center justify-center"
  >
    <v-progress-circular
      v-if="statistics === undefined"
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <span v-else>
      <span v-if="isUserLocal">
        You haven't played any matches in our divisions!
      </span>
      <span v-else>
        We have no statistics for this user!
      </span>
    </span>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAlertOctagon,
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiCurrencyEur,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiPlayCircle,
  mdiSkull,
  mdiAmmunition,
  mdiPistol,
  mdiSkullScan,
  mdiTrophy,
  mdiTrophyOutline,
  mdiNumeric5Box,
  mdiStarShooting,
  mdiAssistant,
} from '@mdi/js'

export default {
  props: ['isUserLocal', 'statistics'],
  setup(props) {
    const statisticsData = [
      {
        id: 'match',
        title: 'Matches',
      },
      {
        id: 'win',
        title: 'Wins',
      },
      {
        id: 'loss',
        title: 'Losses',
      },
      {
        id: 'round',
        title: 'Rounds played',
      },
      {
        id: 'death',
        title: 'Deaths',
      },
      {
        id: 'penta',
        title: 'Penta kills',
      },
      {
        id: 'avg_kd',
        title: 'AVG K/D',
      },
      {
        id: 'avg_hs',
        title: 'AVG HS %',
      },
      {
        id: 'avg_k',
        title: 'AVG Kills',
      },
      {
        id: 'avg_death',
        title: 'AVG Deaths',
      },
      {
        id: 'avg_assists',
        title: 'AVG Assists',
      },
      {
        id: 'avg_mvp',
        title: 'AVG MVPs',
      },
    ]

    const resolveStatisticsIconVariation = data => {
      if (data === 'AVG K/D') return { icon: mdiAmmunition, color: 'stats' }
      if (data === 'AVG HS %') return { icon: mdiSkullScan, color: 'stats' }
      if (data === 'AVG Kills') return { icon: mdiPistol, color: 'stats' }
      if (data === 'Deaths') return { icon: mdiSkull, color: 'stats' }
      if (data === 'Matches') return { icon: mdiTrophyOutline, color: 'stats' }
      if (data === 'Wins') return { icon: mdiTrophyOutline, color: 'stats' }
      if (data === 'Losses') return { icon: mdiTrophyOutline, color: 'stats' }
      if (data === 'AVG Deaths') return { icon: mdiSkull, color: 'stats' }
      if (data === 'AVG Assists') return { icon: mdiAssistant, color: 'stats' }
      if (data === 'AVG MVPs') return { icon: mdiStarShooting, color: 'stats' }
      if (data === 'Rounds played') return { icon: mdiPlayCircle, color: 'stats' }
      if (data === 'Penta kills') return { icon: mdiNumeric5Box, color: 'stats' }

      return { icon: mdiAccountOutline, color: 'stats' }
    }

    return {
      statisticsData,
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiDotsVertical,
        mdiAssistant,
        mdiNumeric5Box,
        mdiTrophyOutline,
        mdiStarShooting,
        mdiPlayCircle,
        mdiSkullScan,
        mdiAmmunition,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiAlertOctagon,
        mdiCurrencyEur,
        mdiSkull,
        mdiPistol,
        mdiTrophy,
      },
    }
  },
}
</script>

<style scoped>
.progressbar-player-statistics {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #312d4b;
  min-height: 75px;
}
</style>
