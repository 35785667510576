<template>
  <div id="user-view">
    <v-row v-if="userData !== undefined && userData !== null">
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <user-bio-panel
          :user-data="userData"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
        ></user-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            No content to show here right now!
          </v-tab-item>
          <!--
          <v-tab-item>
            <user-tab-security></user-tab-security>
          </v-tab-item>
          <v-tab-item>
            <user-tab-billings-plans :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"></user-tab-billings-plans>
          </v-tab-item>
          <v-tab-item>
            <user-tab-notifications></user-tab-notifications>
          </v-tab-item>
          <v-tab-item>
            <user-tab-connections></user-tab-connections>
          </v-tab-item>
        </v-tabs-items> -->
        </v-tabs-items>
      </v-col>
    </v-row>
    <div
      v-else
      class="progressbar-player-profile d-flex align-center justify-center"
    >
      <v-progress-circular
        v-if="userData === undefined"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <span v-else>
        This player hasn't logged into our website yet.
      </span>
    </div>
  </div>
</template>

<script>
import { mdiAccountOutline, mdiLockOutline, mdiBookmarkOutline, mdiBellOutline, mdiLinkVariant } from '@mdi/js'
import { ref } from '@vue/composition-api'
import UserBioPanel from '@/views/components/profile/user-bio-panel/UserBioPanel.vue'
import UserTabOverview from '@/views/components/profile/user-tab-overview/UserTabOverview.vue'

// import UserTabSecurity from './user-tab-security/UserTabSecurity.vue'
// import UserTabBillingsPlans from './user-tab-billings-plans/UserTabBillingsPlans.vue'
// import UserTabNotifications from './user-tab-notifications/UserTabNotifications.vue'
// import UserTabConnections from './user-tab-connections/UserTabConnections.vue'

export default {
  components: {
    UserBioPanel,
    UserTabOverview,

    // UserTabSecurity,
    // UserTabBillingsPlans,
    // UserTabNotifications,
    // UserTabConnections,
  },
  data() {
    return {
      isPlanUpgradeDialogVisible: true,
      id: this.$route.params.id,
    }
  },
  computed: {
    userData() {
      return this.id === undefined
        ? this.$store.getters['user/GetLocalUserData']
        : this.$store.getters['user/GetUserData'](this.id)
    },
  },
  created() {
    if (this.id !== undefined) {
      const localId = this.$store.getters['user/GetLocalUserData']?.faceit_id
      if (this.id === localId) {
        this.$router.push('/profile')

        return
      }
      const result = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(this.id)
      if (!result) {
        this.$router.push('/error-404')

        return
      }
      this.$store.dispatch('user/loadUserData', this.id)
    }
  },
  setup() {
    const userTab = ref(null)
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)

    const plansList = [
      { text: 'Basic - $0/month', value: 'basic' },
      { text: 'Standard - $99/month', value: 'standard' },
      { text: 'Enterprise - $499/month', value: 'enterprise' },
      { text: 'Company - $999/month', value: 'company' },
    ]

    const tabs = [
      { icon: mdiAccountOutline, title: 'Overview' },

      // { icon: mdiLockOutline, title: 'Security' },
      // { icon: mdiBookmarkOutline, title: 'Billing & Plan' },
      // { icon: mdiBellOutline, title: 'Notifications' },
      // { icon: mdiLinkVariant, title: 'Connections' },
    ]

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      tabs,
      userTab,
      plansList,
      selectedPlan,
      isPlanUpgradeDialogVisible,
      resolveCurrentPlanValue,
    }
  },
}
</script>

<style scoped>
.progressbar-player-profile {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #312d4b;
  min-height: 75px;
}
</style>
