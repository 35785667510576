<template>
  <v-col
    md="12"
    lg="12"
    cols="12"
  >
    <v-row class="">
      <v-col
        cols="12"
        sm="12"
      >
        <player-statistics :statistics="statistics"></player-statistics>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import PlayerStatistics from '@/views/components/league/PlayerStatistics.vue'

export default {
  components: {
    PlayerStatistics,
  },
  props: ['statistics'],
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
